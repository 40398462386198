<template>
  <div class="order-list">
    <van-tabs
      v-model="orderStatus"
      sticky
      offset-top="46"
      title-active-color="#00c4b3"
      title-inactive-color="#878D99"
      color="#00c4b3"
      @change="onTabChange">
      <van-tab v-for="tab in tabList" :key="tab.title" :name="tab.value">
        <template #title>
          <span class="font16 bold">
            {{ tab.title }}
          </span>
        </template>
        <van-pull-refresh
          class="card-ul"
          v-model="isLoading"
          @refresh="onRefresh">
          <div
            class="card-item"
            v-for="(item, index) in orderList"
            :key="index"
            @click="$router.push(`order-detail/${item.orderNo}`)">
            <div class="card-header">
              <span class="hospital-name van-ellipsis">{{
                item.hospitalName
              }}</span>
              <span
                class="status-name"
                :style="{ color: getStatusColor(item.orderStatus) }"
                >{{ item.statusName }}</span
              >
            </div>
            <div class="card-main">
              <div class="img-container">
                <img
                  v-if="item.productImage"
                  :src="item.productImage | filterImg"
                  alt="" />
                <div class="sex-key" v-if="item.dictSexKey === '男'">
                  限男性
                </div>
                <div class="sex-key" v-else-if="item.dictSexKey === '女'">
                  限女性
                </div>
                <div class="sex-key" v-else>男女通用</div>
              </div>
              <div class="card-info flex-column">
                <div class="card-title bold">{{ item.productName }}</div>
                <div class="card-types">
                  <div>
                    <span>{{ item.projectItemCount }}个检查项目</span>
                    <span>筛查{{ item.diseaseItemCount }}个疾病</span>
                  </div>
                  <span
                    v-for="(tag, index) in item.diseaseItems"
                    :key="index"
                    >{{ tag }}</span
                  >
                </div>
              </div>
            </div>

            <div class="card-footer">
              <template v-if="item.orderStatus === 0">
                <van-count-down :time="item.remainingPaymenTime">
                  <template #default="timeData">
                    <span class="time-date">
                      剩余
                      <span style="color: #ffb470"
                        >{{ timeData.minutes }}:{{ timeData.seconds }}</span
                      >
                      关闭订单
                    </span>
                  </template></van-count-down
                >
                <div class="card-price">
                  <span style="font-size: 12px" class="sale-price">￥</span>
                  <span class="sale-price">{{ item.amountReceived }}</span>
                </div>
              </template>
              <div v-if="item.orderStatus !== 0 && item.createDate">
                下单时间：{{ item.createDate }}
              </div>
            </div>
            <div class="footer-button-container">
              <span
                :class="button.className"
                v-for="button in getFooterButton(item)"
                @click.stop="buttonClick(button.name, item)"
                >{{ button.name }}</span
              >
            </div>
          </div>
        </van-pull-refresh>
        <div class="empty-container" v-if="!orderList.length">
          <img class="empty-img" src="@/assets/img-empty.png" alt="" />
          <div class="empty-title">暂无预约信息</div>
          <div class="empty-desc">
            请点击下方“查看套餐”去预约 合适的体检套餐吧～
          </div>
          <div class="empty-button" @click="jumpHome">查看套餐</div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { GetOrders, CancelOrder, Repay, QueryStatus } from "@/api";
import { Dialog } from "vant";
import { isWechat } from "@/utils/index.js";

import { isNull } from "@/utils/index.js";
export default {
  name: "order-list",
  data() {
    return {
      tabList: [
        {
          title: "全部",
          value: -1,
        },
        {
          title: "待付款",
          value: 0,
        },
        {
          title: "已预约",
          value: 3,
        },
        {
          title: "售后",
          value: 4,
        },
      ],
      orderStatus: -1,
      orderList: [],
      isLoading: false,
      payInfo: {},
    };
  },
  created() {
    // this.$setTitle("我的订单");
    this.$store.commit("setTitle", "我的订单");
    console.log(this.$route);
    if (!isNull(this.$route.params.status)) {
      this.orderStatus = this.$route.params.status;
    }
    this.getOrderList();
  },
  methods: {
    async onRefresh() {
      this.isLoading = true;
      this.getOrderList();
      this.isLoading = false;
    },
    async getOrderList() {
      const params = {
        PageNo: 1,
        PageSize: 1000,
        OrderSource: 10,
        orderStatus: this.orderStatus,
      };
      let data = await GetOrders(params);
      this.orderList = data.rows;
    },
    onTabChange(name) {
      this.orderStatus = name;
      this.getOrderList();
    },
    getFooterButton(item) {
      const { orderStatus, payee } = item;
      let list = [];
      if (orderStatus === 0) {
        list = [
          {
            name: "取消订单",
            className: "button-default",
          },
          {
            name: "立即付款",
            className: "button-primary",
          },
        ];
      } else if (orderStatus === 1 || orderStatus === 2) {
        list = [
          {
            name: "修改预约",
            className: "button-default",
          },
        ];
      }
      if ([1, 2, 3].includes(orderStatus) && payee !== 1) {
        list.unshift({
          name: "申请退款",
          className: "button-default",
        });
      }
      return list;
    },
    getStatusColor(orderStatus) {
      if ([0, 1, 2, 3, 4].includes(orderStatus)) {
        return "#FFB470";
      } else if ([5, 6].includes(orderStatus)) {
        return "#00C4B3";
      } else {
        return "#ccc";
      }
    },
    buttonClick(str, item) {
      if (str === "立即付款") {
        this.handlerPay(item.orderNo);
      } else if (str === "取消订单") {
        this.cancelOrder(item.orderDetlId);
      } else if (str === "修改预约") {
        this.$router.push(
          "/edit-appointment/" +
            item.orderNo +
            "?hospitalProductId=" +
            item.hospitalProductId
        );
      } else if (str === "申请退款") {
        this.$router.push("/refund-detail/" + item.orderNo);
      }
    },
    //取消订单
    cancelOrder(orderDetailId) {
      Dialog.confirm({
        title: "取消订单",
        message: "是否确定取消订单？",
        confirmButtonColor: "#00c4b3",
      })
        .then(async () => {
          // on confirm
          const res = await CancelOrder({
            orderDetailId,
            refundReasonCode: "9",
          });
          location.reload();
        })
        .catch(() => {
          // on cancel
        });
    },
    async handlerPay(orderNo) {
      let data = await Repay({
        orderNo: orderNo,
        appId: process.env.VUE_APP_APPID,
        openId: this.$store.state.openid,
        paymentType: 0, // 支付平台 Tenpay = 0 Alipay = 1
        paymentMethod: isWechat() ? 1 : 2, // JsApi = 1  H5 = 2 Pc = 3
      });
      if (data.h5Url) {
        location.href = data.h5Url;
      } else {
        this.payInfo = data;
        this.weixinPay();
      }
    },
    onBridgeReady(orderNo) {
      const _this = this;
      console.log(_this.payInfo);
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: _this.payInfo.appId, //公众号名称，由商户传入
          timeStamp: _this.payInfo.timestamp, //时间戳，自1970年以来的秒数
          nonceStr: _this.payInfo.nonceStr, //随机串
          package: _this.payInfo.prepayIdPackage,
          signType: "RSA", //微信签名方式：
          paySign: _this.payInfo.signature, //微信签名
        },

        async (res) => {
          const { payStatus } = await _this.QueryStatus(orderNo);
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            if (payStatus) {
              _this.$toast("支付成功");
              _this.getOrderList();
            }
            // this.gametwoApplyWx()
            // webPayReturnToOrder({gameOrderId: _this.payInfo.gameOrderId})
            // _this.$router.push('/success')
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else {
            console.log(res.err_msg, "wx_pay");
            _this.$toast("支付失败,请重新支付");
          }
        }
      );
    },
    weixinPay(orderNo) {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            this.onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", this.onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", this.onBridgeReady);
        }
      } else {
        this.onBridgeReady(orderNo);
      }
    },
    async QueryStatus(orderNo) {
      if (!orderNo) return;
      let data = await QueryStatus({ orderNo });
      return data;
    },
    //跳转首页
    jumpHome() {
      this.$router.push("home?hospitalId=" + this.$store.state.hospitalId);
    },
  },
};
</script>

<style>
body {
  background-color: #f4f4f4 !important;
}
</style>

<style lang="scss" scoped>
.order-list {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.card-ul {
  padding: 10px;
}

.card-item {
  width: 100%;
  padding: 0 10px 13px 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #fff;
  position: relative;
  border-radius: 8px;
  z-index: 1;
  .img-container {
    position: relative;
    width: 80px;
    height: 95px;
    border-radius: 8px;
    margin-right: 10px;
  }

  .img-container img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
  }

  .sex-key {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 7px;
    background: linear-gradient(220deg, #ee7c3c 3%, #ff3d00 100%);
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    height: 15px;
    color: #fff;
  }
  .card-title {
    color: #333;
    font-size: 15px;
    line-height: 18px;
  }
  .card-info {
    flex: 1;
  }
  .card-types {
    span {
      display: inline-block;
      padding: 3px 4px;
      margin: 4px 5px 0 0;
      color: #878d99;
      background: #f8f8f8;
      border-radius: 4px;
      font-size: 12px;
    }
    div {
      font-size: 12px;
      span:nth-child(-n + 2) {
        color: #0091ff;
        background: rgba(60, 196, 255, 0.1);
        font-size: 12px;
      }
    }
  }
  .card-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sale-price {
      font-size: 20px;
      color: #ff3d00;
      font-weight: bold;
    }
  }
}
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  font-size: 15px;
  font-family: PingFang SC-Bold, PingFang SC;
}

.hospital-name {
  color: #475266;
}

.status-name {
  margin-left: 20px;
  white-space: nowrap;
  font-weight: bold;
  color: #ffb470;
}

.card-main {
  display: flex;
}

.card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #2c2f37;
  line-height: 14px;
}

.time-date {
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  color: #2c2f37;
}

.footer-button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.button-default,
.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  padding: 0 12px;
  margin-left: 10px;
  font-weight: bold;
  line-height: 16px;
  border-radius: 15px;
}

.button-primary {
  color: #00c4b3;
  background: rgba(0, 196, 179, 0.1);
  border: 1px solid #00c4b3;
}

.button-default {
  color: #878d99;
  border: 1px solid #edeff0;
}

.empty-container {
  margin-top: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.empty-img {
  width: 90px;
  height: 90px;
}

.empty-title {
  margin-top: 10px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #2c2f37;
  line-height: 19px;
}

.empty-desc {
  margin-top: 15px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #878d99;
  line-height: 22px;
  width: 200px;
  text-align: center;
}

.empty-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 180px;
  height: 40px;
  background: #00c4b3;
  border-radius: 20px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #ffffff;
}
</style>
